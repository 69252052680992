import React from 'react';
import axios from 'axios';
import ContentLibraryItem from './ContentLibraryItem';
import {Card} from '@material-ui/core';
import LoadingItem from './LoadingItem';
import {Button} from '@material-ui/core';
import ContentSettings from './ContentSettings';
import Box from '@material-ui/core/Box';
import PageHitChart from './PageHitChart';
import {DropzoneArea} from 'material-ui-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DirectoryItem from './DirectoryItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import * as ETVConstants from '../ETVConstants';

class ContentLibrary extends React.Component {

	constructor(props) {
      super(props);
			this.deleteContentID = this.deleteContentID.bind(this);
			this.setActiveF = this.setActiveF.bind(this);
			this.setPublicF = this.setPublicF.bind(this);
      this.showSettings = this.showSettings.bind(this);
      this.closeSettings = this.closeSettings.bind(this);
      this.addedToChannelLocal = this.addedToChannelLocal.bind(this);
      this.showHitReport = this.showHitReport.bind(this);
      this.closeWindow = this.closeWindow.bind(this);
			this.changeDir = this.changeDir.bind(this);
			this.deleteDir = this.deleteDir.bind(this);
    }

    state = {
      isLoaded: false,
			loading: false,
			loading_info: '',
			loading_percentage: 0,
			settingsID:undefined,
			window: '',
      items: [],
      info_title:'',
      info_message:'',
			available_users:[],
			current_dir:"/",
			all_dirs:[],
			new_folder_text:'',
			new_folder_error:'',
			dir_has_contents:[],
			filter_type:'*',
			filter_name:'',
    }

		createDate( yearsPlus) {
			var d = new Date();
			var formatted_date = (yearsPlus + d.getFullYear()) + "-" + (d.getMonth() + 1) + "-" + d.getDate();
			return new Date(formatted_date);
		}

    handleData(items) {
      return items;
    }

    componentDidMount() {
			this.loadData();
			if(this.props.user_id) this.loadAvailableUsers(this.props.user_id);
    }

		componentDidUpdate(prevProps, prevState) {
			if(!this.state.isLoaded && !this.state.loading) {
				this.loadData();
			}
		}

		showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }


		filterOutTemplates( rx) {
			var res = [];
			for(var i=0; i<rx.length; i++) {
				if(rx[i].content_category!=="template") {
					res.push(rx[i]);
				}
			}
			return res;
		}

		getDirHasContents(items) {
			var res = [];
			for(var i=0; i<items.length; i++) {
				var virtualDir = items[i].virtual_dir;
				if(!virtualDir.endsWith("/")) {
					virtualDir=virtualDir+"/";
				}
				//also add all subdirs!
				if(items[i].filename!=='virtual_dir') {
					res.push(virtualDir);
					var subDirs = this.getSubDirs(virtualDir);
					for(var k=0; k<subDirs.length; k++) {
							if(!res.includes(subDirs[k])) {
								res.push(subDirs[k]);
							}
					}
				}
			}
			//console.log("dirs have content="+res);
			return res;
		}

		filterOutMovies( list) {
			var res = [];
			for(var i=0; i<list.length; i++) {
				if(!this.isMovie(list[i].filename)) {
					res.push(list[i]);
				}
			}
			return res;
		}

    loadData() {
			//console.log("load data, marketplace="+this.props.marketplace);
			this.setState({loading:true});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = "";
      if(this.props.marketplace) {
			    url = ETVConstants.getServerBase()+"/content_library?action=list_market_place";
      }else{
          url = ETVConstants.getServerBase()+"/content_library?action=list&user_id="+this.props.user_id;
      }
			//console.log("load items url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
            var rx = result.list.reverse();
						if(this.props.marketplace) {
							rx = this.filterOutTemplates(rx);
						}
						if(this.props.channel>0) {
							rx = this.filterOutMovies(rx);
						}
						var allDirs = this.getDirs(rx);
						//console.log("-----> (after loading, allDirs="+allDirs);
            this.setState({
              isLoaded: true,
              items: rx,
							all_dirs:allDirs,
							dir_has_contents:this.getDirHasContents(rx),
							loading: false,
							filter_type:'*',
							filter_name:'',
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
						//console.log(error);
            this.setState({
              isLoaded: false,
							loading: false,
							filter_type:'*',
							filter_name:'',
              error
            });
          }
        )
    }

		getDirs( items) {
			var res = [];
			res.push("/");
			var myID = this.props.user_id;
			for(var i=0; i<items.length; i++) {
					var itemOwnerID = items[i].owner_id;
					//console.log("myID="+myID+" ownerID="+itemOwnerID);
					// only show directy if it belongs to set_myself
					// its only a virtual directory (to avoid double directories)
					if(itemOwnerID===myID) {
						var virtualDir = items[i].virtual_dir;
						//console.log("filename="+items[i].filename+" itemOwner("+itemOwnerID+")==="+myID+" virtualDir="+virtualDir);
						var subDirs = this.getSubDirs(virtualDir);
						//console.log("subDirs="+subDirs);
						for(var k=0; k<subDirs.length; k++) {
								if(!res.includes(subDirs[k])) {
									res.push(subDirs[k]);
								}
						}
					}else{
						//console.log("filename="+items[i].filename+" itemOwner("+itemOwnerID+")!=="+myID+" virtualDir="+items[i].virtual_dir);
					}
			}
			res.sort(function (a, b) {
				return a.toLowerCase().localeCompare(b.toLowerCase());
			});
			return res;
		}

		getSubDirs( virtualDir) {
			var res = [];
			res.push(virtualDir);
			var temp = virtualDir;
			var lastIndex = temp.lastIndexOf("/");
			while(lastIndex!==0) {
				temp = temp.substring(0,lastIndex);
				var newEntry = temp+"/";
				if(!res.includes(newEntry)) {
					res.push(newEntry);
				}
				lastIndex = temp.lastIndexOf("/");
			}
			return res;
		}

		deleteContentID( contentID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/content_library?action=delete&content_id="+contentID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							//console.log("delete result="+JSON.stringify(result));
							this.setState({isLoaded:false});
						},
						(error) => {
							//console.log("yyy-------> "+error);
						}
					)
		}

		setActiveF( contentID, isActive) {
			var url = ETVConstants.getServerBase()+"/content_library?action=set_active&content_id="+contentID+"&active="+isActive;
			this.updateContentRegistry(url);
		}

		setPublicF( contentID, isPublic) {
      var url = ETVConstants.getServerBase()+"/content_library?action=make_public&content_id="+contentID+"&is_public="+isPublic;
      this.updateContentRegistry(url);
    }

		updateContentRegistry( url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							this.setState({isLoaded:false});
						},
						(error) => {
						}
					)
		}

    isMovie( filen) {
			var filename = filen.toLowerCase().trim();
      if (filename.endsWith(".mpg")) return true;
      if (filename.endsWith(".mpeg")) return true;
      if (filename.endsWith(".mp4")) return true;
			if (filename.endsWith(".webm")) return true;
			if (filename.endsWith(".mkv")) return true;
			if (filename.endsWith(".3gp")) return true;
			if (filename.endsWith(".avi")) return true;
			if (filename.endsWith(".mov")) return true;
			if (filename.endsWith(".wmv")) return true;
      return false;
    }

    isHTML( filename) {
      if (filename.endsWith(".htm")) return true;
      if (filename.endsWith(".html")) return true;
      return false;
    }

    getThumbnail(elem) {
      var imageUrl = elem.thumbnail;
      if(!imageUrl) {
        if(this.isMovie(elem.filename.toLowerCase())) {
          imageUrl = "/images/content/movie_placeholder.png";
        }else if(this.isHTML(elem.filename.toLowerCase())) {
          imageUrl = "/images/content/html_placeholder.png";
        }
      }
      return imageUrl;
    }

		findElement(id) {
        //console.log("find element with ID="+id+" items.length="+this.state.items.length);
				for(var i=0; i<this.state.items.length; i++) {
						if(this.state.items[i].content_id===id) {
              //console.log("FOUND ELEMENT="+this.state.items[i]);
							return this.state.items[i];
						}
				}
        //console.log("---------------> NOT FOUND <---------------");
		}

    showSettings( contentID) {
      var elem = this.findElement(contentID);
      //console.log("show Settings for libraryElementID="+contentID+" element="+elem);
      this.setState( {library_element:elem} );
      this.showWindow('content_settings_div',true);
    }

    showHitReport( contentID) {
      var elem = this.findElement(contentID);
      //console.log("show HitReport for libraryElementID="+contentID+" element="+elem);
      this.setState( {library_element:elem} );
      this.showWindow('hit_report_div',true);
    }

		showWindow(divID, show) {
			show?this.setState({window:divID}):this.setState({window:''});
		}

    showMessage( title, message) {
      this.setState({window:'message_div',info_title:title,info_message:message});
    }

		printFormData( formData) {
			for (var pair of formData.entries()) {
    			console.log("xFORMDATA=========>"+pair[0]+ '=' + pair[1]);
			}
		}

		getDefaultDesc() {
			var d = new Date();
			var t = d.toLocaleTimeString();
			var da = d.toLocaleDateString();
			return da+" - "+t;
		}

		//note: this is only for cloud!!
		registerContent( target_dir, filename, contentRatio, fileSize) {
			//console.log("------ register content, target_dir="+target_dir+" filename="+filename);
			this.setState({loading_info:'register content in database',loading_percentage:100});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var userID = this.props.user_id;
			var desc = this.getDefaultDesc();
			var target_dir2 = "/"+userID+"/content_library";
			var url = ETVConstants.getServerBase()+"/content_library?action=register_content&desc="+desc+"&user_id="+userID+"&target_dir="+encodeURIComponent(target_dir2)+"&content_ratio="+contentRatio+"&file_size="+fileSize+"&virtual_dir="+encodeURIComponent(this.state.current_dir)+"&filename="+encodeURIComponent(filename);
			//console.log("xxxxxxx->"+url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							//console.log(JSON.stringify(result));
							if(result["media_url"]) {
								// reload items
								this.setState({isLoaded:false,loading:false,loading_info:'',loading_percentage:0.0});
							}else{
								//console.log(JSON.stringify(result));
								this.showInfoMessage(ETVConstants.trans("error_processing_image_file"));
								this.setState({isLoaded:false,loading:false,loading_info:'',loading_percentage:0.0});
							}
						},
						(error) => {
								//console.log("error="+error);
								this.showInfoMessage(ETVConstants.trans("error_processing_image_file"));
								this.setState({isLoaded:false,loading:false,loading_info:'',loading_percentage:0.0});
						}
					)
		}

		updateProgress( total, current) {
				var percentage = Math.floor(100*current/total);
				//sometimes shows more than 100
				percentage = Math.min(100,percentage);
				this.setState({loading_info:'uploading file ('+percentage+'%)',loading_percentage:percentage});
		}

		getMB( len) {
			var num = len/1024/1024;
			var mb = Math.round(num * 100) / 100;
			return mb+"MB";
		}

		uploadFileToOnPremise( file, contentRatio) {
			//console.log("uploading to onpremise");
			this.setState({loading_info:'prepare to upload file ('+this.getMB(file.size)+')',loading_percentage:1.1});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");

			var formData = new FormData();
			formData.append('content_file', file);
			var userID = this.props.user_id;
			formData.append('user_id',userID);
			var filename = file.name;
			formData.append('filename', filename);
			var target_dir = "/"+userID+"/content_library";
			formData.append('target_dir',target_dir);
			formData.append('desc',filename);
			formData.append('content_ratio',contentRatio);
			formData.append('virtual_dir',this.state.current_dir);

			const config = {
				headers: {	'content-type': 'multipart/form-data'},
				onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
			}

			var url = ETVConstants.getServerBase()+"/on_premise_upload";
			axios.post(url, formData, config)
					.then((response) => {
						//console.log("result="+JSON.stringify(response));
						this.setState({loading_percentage:100,loading_info:'done',loading:false,isLoaded:false});
					})
					.catch((error) => {
						 console.error(error);
					});
		}

		//for the cloud version
		uploadFileToGCS( file, contentRatio) {
			this.setState({loading_info:'prepare to upload file ('+this.getMB(file.size)+')',loading_percentage:1.1});
			//console.log("---------------------uploading data");
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");

			var userID = this.props.user_id;
			var target_dir = "/"+userID+"/content_library";
      var myRand = Math.floor((Math.random() * 100000) + 1);
      var filename = myRand+"_"+file.name;
			var url = ETVConstants.getServerBase()+"/content_library?action=request_upload&user_id="+userID+"&target_dir="+target_dir+"&filename="+encodeURIComponent(filename);
      //console.log("xxxxxxxxxxxxxx request Upload, url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						var formData = new FormData();
						//console.log("------>"+JSON.stringify(result));
						//formData.append('filename',file.name);
						//console.log("post to url="+result.url);

						Object.entries(result.fields).map(([key,value])=>{
								formData.append(key,value);
								//console.log("1=======>"+key+"="+value);
						})
						formData.append('file', file);

						//formData.append('success_action_redirect',)

						const config = {
	    				headers: {	'content-type': 'multipart/form-data'},
							onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
						}
						this.setState({loading_info:'uploading file ('+this.getMB(file.size)+')'});

						axios.post(result.url, formData, config)
								.then((response) => {
									//console.log("response="+JSON.stringify(response));
									//console.log("register targetDir="+target_dir+" filename="+filename+" contentRatio="+contentRatio+" file.size="+file.size);
                  this.registerContent(target_dir,filename,contentRatio,file.size);
									//this.registerContent(target_dir,file.name);
									//console.log("esponse.data="+response.data);
									//console.log("respones status="+response.status);
									//console.log("response statusText="+response.statusText);
									//console.log(response.headers);
									//console.log(response.config);
								})
								.catch((error) => {
									 //console.error(error);
								});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						//console.log("errrrrror="+error);
					}
				)
		}

		onDrop = (files) => {
				this.setState({loading:true,loading_info:'loading'});
				for( var i=0; i<files.length; i++) {
					var file = files[i];
					if(this.isImage(file.name)) {
						this.setState({loading_info:'analyzing image'});
						var reader  = new FileReader();
						reader.onloadend = (e) => {
							//console.log("reader onload end");
				      //const content = e.target.result;
				      //console.log("readerLoaded="+content);
				      var image = new Image();
				      image.src = e.target.result;
				      image.onload = (img) => {
								//console.log("YYYYYYYYYYYYYYYYYYY img="+JSON.stringify(img)+" /ifffmg.width="+image.width+" hffeight="+image.height);
								var ratio = image.width/image.height;
								//console.log("ratio="+ratio);
								this.setState({loading_percentage:1.1,loading_info:'resolution: '+image.width+"x"+image.height+" px, size="+this.getMB(file.size)});
								if(ETVConstants.ON_PREMISE_VERSION) {
										this.uploadFileToOnPremise( file, ratio);
								}else{
										this.uploadFileToGCS( file, ratio);
								}
					    }
				    }
						reader.readAsDataURL(file);
					}else{
						this.setState({loading_info:"size="+this.getMB(file.size)});
						if(ETVConstants.ON_PREMISE_VERSION) {
								this.uploadFileToOnPremise( file, -1.0);
						}else{
								this.uploadFileToGCS( file, -1.0);
						}
					}
				}
		}

		isImage(name) {
			if(!name || name.length===0) return false;
			if(name.trim().toLowerCase().endsWith(".jpg")) return true;
			if(name.trim().toLowerCase().endsWith(".jpeg")) return true;
			if(name.trim().toLowerCase().endsWith(".gif")) return true;
			if(name.trim().toLowerCase().endsWith(".png")) return true;
			if(name.trim().toLowerCase().endsWith(".webp")) return true;
			if(name.trim().toLowerCase().endsWith(".bmp")) return true;
			return false;
		}

		setContains( setItems, item) {
      for(var i=0; i<setItems.length; i++) {
        if(setItems[i]===item) {
          return true;
        }
      }
      return false;
    }

    closeSettings() {
      this.showWindow('content_settings_div',false);
      this.setState({isLoaded:false});
    }

    closeWindow() {
      this.showWindow('',false);
      this.setState({isLoaded:false});
    }

    filter( items) {
      if(!items) return;
      if(!this.props.excluded_ids) return items;

      var result = [];
      var excludedIDs = this.props.excluded_ids;
      for(var i=0; i<items.length; i++) {
        if(!this.setContains(excludedIDs,items[i].content_id)) {
          result.push(items[i]);
        }
      }
      return result;
    }

		loadAvailableUsers( userID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/useradmin?action=list&user_id="+userID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						//console.log("----->available users="+JSON.stringify(result));
						this.setState({
							available_users: result.data,
						});
					},
					(error) => {
					}
				)
		}

    addedToChannelLocal(channel, item) {
      //var filename = item.filename.substring(item.filename.indexOf("_")+1);
      this.showMessage(ETVConstants.trans("success"),ETVConstants.trans("the_content_was_stored_in_your_channel"));
      this.props.addedToChannelF();
    }

		getFilename(filename) {
			return filename.substring(filename.indexOf('_')+1);
		}

		canEdit( ownerID) {
			return this.props.user_id===ownerID || this.props.children.includes(ownerID);
		}

		makeInternalDirRepresentation( dir) {
			if(dir.endsWith("/")) return dir;
			return dir+"/";
		}

		filterDir(items) {
			var res = [];
			var userID = this.props.user_id;
			for(var i=0; i<items.length; i++) {
					//console.log("filename="+items[i].filename);
					var filename = items[i].filename;
					var itemDir = this.makeInternalDirRepresentation(items[i].virtual_dir);
					if(filename!=='virtual_dir') {
						if(itemDir===this.state.current_dir || (items[i].owner_id!==userID && this.state.current_dir==="/")  ) {
							//show the content if you are not the owner (but only in root folder "/")
							res.push(items[i]);
						}
					}
			}
			return res;
		}

		isChild( virtualDir, currentDir) {
			if(virtualDir===currentDir) return false;
			if(currentDir==="/") return true;
			if(virtualDir.indexOf(currentDir+"/")!==0) return false;
			return true;
		}

		getChildrenDirs(currentDir) {
			var res = [];
			for(var i=0; i<this.state.all_dirs.length; i++) {
				// /carpark/abc/11234
				// /carparkkkkk/33333/
				// /carkark/abc
				var temp = this.state.all_dirs[i];
				if(temp.endsWith("/")) {
					temp = temp.substring(0,temp.length-1);
				}
				var removeStart = currentDir;
				if(temp.startsWith(removeStart)) {
					temp = temp.substring(removeStart.length);
					if(temp.length>0 && temp.indexOf("/")===-1) {
							var candidate = removeStart+temp+"/";
							res.push(candidate);
					}
				}
			}
			return res;
		}

		showAddDirectory() {
			this.showWindow("add_directory",true);
		}

		changeDir( dir) {
			this.setState({current_dir:dir});
		}

		createUpDir( curDir) {
			if(curDir==="/") return null;
			//curDir contains "/" at end
			var upDir = curDir.substring(0,curDir.length-1);
			upDir = upDir.substring(0,upDir.lastIndexOf("/")+1);
			return upDir;
		}

		checkDirectoryInput( e) {
				var newValue = e.target.value;
				if (ETVConstants.isAlphaNumeric(newValue)) {
					this.setState({new_folder_error:"",new_folder_text:newValue});
	  		} else {
					this.setState({new_folder_error:"no special characters allowed"});
	  		}
		}

		sendAddDirectory() {
			var newDir = this.state.current_dir+this.state.new_folder_text+"/";
			if(this.state.all_dirs.includes(newDir)) {
				this.showMessage(ETVConstants.trans("error"),"the directory "+newDir+" already exists");
				return;
			}
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var userID = this.props.user_id;
			var url = ETVConstants.getServerBase()+"/content_library?action=create_virtual_directory&user_id="+userID+"&virtual_directory="+encodeURIComponent(newDir);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							//console.log(JSON.stringify(result));
							this.closeWindow();//will automatically reload
							this.setState({new_folder_text:""});
						},
						(error) => {
								//console.log("error="+error);
						}
				)
		}

		deleteDir(dir) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var userID = this.props.user_id;
			var url = ETVConstants.getServerBase()+"/content_library?action=delete_virtual_directory&user_id="+userID+"&virtual_directory="+encodeURIComponent(dir);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							//console.log(JSON.stringify(result));
							this.closeWindow();//will automatically reload
						},
						(error) => {
								//console.log("error="+error);
						}
				)
		}

		dirHasContents(dir) {
			var hasContents = this.state.dir_has_contents.includes(dir);
			if(hasContents) return true;
			//has childFolders?
			for(var i=0; i<this.state.all_dirs.length; i++) {
				if(this.state.all_dirs[i].startsWith(dir) && this.state.all_dirs[i]!==dir) return true;
			}
			return false;
		}

		randomInt(max) {
		  return Math.floor(Math.random()*(max + 1));
		}

		createDirKey(dir) {
			return dir+"_"+this.randomInt(100);
		}

		filterType(items) {
			var res = [];
			if(this.state.filter_type==='*') return items;
			for(var i=0; i<items.length; i++) {
				var name = items[i].filename;
				if(this.state.filter_type==='movie' && this.isMovie(name)) {
					res.push(items[i]);
				}else if(this.state.filter_type==='image' && this.isImage(name)){
					res.push(items[i]);
				}else if(this.state.filter_type==='html' && this.isHTML(name)) {
					res.push(items[i]);
				}
			}
			return res;
		}

		filterName( items) {
			console.log("filterName, curFilter="+this.state.filter_name);
			var res = [];
			if(!this.state.filter_name || this.state.filter_name.trim().length===0) return items;
			for(var i=0; i<items.length; i++) {
				var n = items[i].filename.toLowerCase();
				var filter = this.state.filter_name.trim().toLowerCase();
				if(n.indexOf(filter)>=0) res.push(items[i]);
			}
			return res;
		}

    render() {
			var items = this.filterDir(this.state.items);
			items = this.filterType(items);
			items = this.filterName(items);
			const dirs = this.getChildrenDirs(this.state.current_dir);
			var upDir = this.createUpDir( this.state.current_dir);
      return(
        <Box width="100%">

						<Box component="section" sx={{ p: 2, border: '0.1px solid lightgrey' }}>
							<FormControl>
								 <InputLabel>{ETVConstants.trans("filter_type")}</InputLabel>
								 <Select
									 labelId={"filter type"}
									 id={"filter_type"}
									 value={this.state.filter_type}
									 style={{ width: 200}}
									 onChange={(e)=>{this.setState({filter_type:e.target.value})}}>
										<MenuItem value='*'>{ETVConstants.trans("all_media")}</MenuItem>
										<MenuItem value='movie'>{ETVConstants.trans("movies")}</MenuItem>
										<MenuItem value='image'>{ETVConstants.trans("images")}</MenuItem>
										<MenuItem value='html'>{ETVConstants.trans("html")}</MenuItem>
								 </Select>
							</FormControl>
						  <FormControl style={{marginLeft:20}}>
									<TextField key='filter_name'
														label={ETVConstants.trans("filter_name")}
														variant='outlined'
														InputProps={{ inputProps: { min: 0, max: 30 } }}
														value={this.state.filter_name}
														style = {{width:250}}
														onChange={(event)=>{this.setState({filter_name:event.target.value})}} />
						  </FormControl>
				    </Box>

						{!this.props.marketplace && items!=null?
							<span>
									<div style={{display:"flex"}}>
		              		{this.props.marketplace?<span/>:
					                <div style={{display: 'inline-block', width: 200, height:'auto', margin: 5}}>
					                      <DropzoneArea
					                          onChange={this.onDrop}
					                          acceptedFiles={['audio/*','text/html', 'image/jpeg', 'image/gif','image/png','image/webp','image/bmp','video/mpeg','video/mp4','video/3gpp','video/webm','video/x-matroska']}
					                          showPreviewsInDropzone={false}
					                          filesLimit={1}
					                          //dropzoneText={ETVConstants.trans("drag_and_drop_file_here_or_click_to_upload_a_new_file")}
					                          maxFileSize={367001600}
					                          showAlerts={false} />
					                </div>
			                }
											{upDir?<DirectoryItem key={"up"} directory={upDir} isUp={true} dirClickedFunc={this.changeDir} />:<span/>}
											{dirs.map( item => {return (<DirectoryItem key={this.createDirKey(item)} hasContents={this.dirHasContents(item)} isUp={false} directory={item} deleteDirFunc={this.deleteDir} dirClickedFunc={this.changeDir}/>)})}
											<Card style={{width:100,height:180,margin:5,padding:20}}>
						              <CardMedia component="img" image="/icons/add_directory.png" alt="add directory" title="add directory"/>
													<CardContent>
															<Typography align="center" variant="body2">&nbsp;</Typography>
													</CardContent>
													<CardActionArea>
															<Box textAlign="center">
																<Button component="span" size='small' variant='contained' color='primary' onClick={()=>{ this.showAddDirectory()}}>{ETVConstants.trans("add_directory")}</Button>
															</Box>
													</CardActionArea>
						          </Card>

											{this.state.loading?
												<div style={{display: 'inline-block', width: 200, height:300, margin: 5}}>
														<LoadingItem info={this.state.loading_info} percentage={this.state.loading_percentage} />
												</div>
												:
												<span/>
											}
											<Divider style={{marginTop:20}}/>
									</div>

								 { items.map( item => {
									 		return 	<div key={item.content_id} style={{display: 'inline-block', width: 200, height:200, margin: 5}}>
													<ContentLibraryItem key={item.content_id}
                                  id={item.content_id}
                                  item={item}
                                  active={item.active}
                                  publicF={this.setPublicF}
                                  channel={this.props.channel}
																	group_id={this.props.group_id}
                                  device_id={this.props.device_id}
                                  user_id={this.props.user_id}
																	children={this.props.children}
                                  showSettingsF={this.showSettings}
                                  activeF={this.setActiveF}
                                  deleteF={this.deleteContentID}
                                  showHitReportF={this.showHitReport}
                                  user_roles={this.props.roles}
																	available_users={this.state.available_users}
                                  addedToChannelF={this.props.addedToChannelF?()=>{this.addedToChannelLocal(this.props.channel,item)}:undefined} />
										      </div>

									})}
							</span>
							:
							<span/>
						}

            {this.props.marketplace && items!=null && items.length>0?
              <div>
  							<div style={{border: '1 px solid blue'}}>
  								 { items.map( item => {
  									 		return 	<div key={item.content_id} style={{display: 'inline-block', width: 200, height:200, margin: 5}}>
  													<ContentLibraryItem key={item.content_id}
                                id={item.content_id}
                                item={item}
                                active={item.active}
                                publicF={this.setPublicF}
                                channel={this.props.channel}
																group_id={this.props.group_id}
                                device_id={this.props.device_id}
                                marketplace={this.props.marketplace}
                                user_id={this.props.user_id}
																children={this.props.children}
                                showSettingsF={this.showSettings}
                                activeF={this.setActiveF}
                                deleteF={this.deleteContentID}
                                user_roles={this.props.roles}
                                showHitReportF={this.showHitReport}
                                addedToChannelF={this.props.addedToChannelF?()=>{this.addedToChannelLocal(this.props.channel,item)}:undefined} />
  										      </div>

  									})}
  							</div>
              </div>
							:
							<span/>
						}

            <Dialog
              open={this.state.window==='message_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={() => this.setState({window:'',aborted:true})  }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
              <DialogContent>
                  <Typography variant='body1'>{this.state.info_message}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({window:'',aborted:true}) } color="primary">{ETVConstants.trans("close")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.window==='content_settings_div'}
              maxWidth='md'
              fullWidth={true}
              onClose={()=>{this.setState({window:'',isLoaded:false})}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{ETVConstants.trans("content_settings")}</DialogTitle>
              <DialogContent>
                  <ContentSettings closeF={this.closeSettings} user_roles={this.props.roles} library_element={this.state.library_element} user_id={this.props.user_id} editable={this.state.library_element?this.canEdit(this.state.library_element.owner_id):false} />
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{this.setState({window:'',isLoaded:false})}} color="primary">{ETVConstants.trans("close")}</Button>
              </DialogActions>
            </Dialog>

						<Dialog
	            open={this.state.window==='message_div'}
	            maxWidth='xs'
	            fullWidth={true}
	            onClose={()=>{this.setState({window:''})}}
	            aria-labelledby="alert-dialog-title"
	            aria-describedby="alert-dialog-description">
	            <DialogTitle id="alert-dialog-title"></DialogTitle>
	            <DialogContent>
	                <Typography variant="body1">{this.state.info_message}</Typography>
	            </DialogContent>
	            <DialogActions>
	              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
	            </DialogActions>
	          </Dialog>

            <Dialog
              open={this.state.window==='hit_report_div'}
              maxWidth='lg'
              fullWidth={false}
              onClose={()=>{this.setState({window:''})}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{ETVConstants.trans("hit_report")}</DialogTitle>
              <DialogContent>
                  {this.state.library_element?
                        <PageHitChart content_id={this.state.library_element.content_id} chart_title={this.getFilename(this.state.library_element.filename)} closeF={this.closeWindow} />
                        :
                        <span/>
                   }
               </DialogContent>
              <DialogActions>
                <Button onClick={()=>{this.setState({window:''})}} color="primary">{ETVConstants.trans("close")}</Button>
              </DialogActions>
            </Dialog>

						<Dialog
	            open={this.state.window==='add_directory'}
	            maxWidth='xs'
	            fullWidth={true}
	            onClose={()=>{}}
	            aria-labelledby="alert-dialog-title"
	            aria-describedby="alert-dialog-description">
	            <DialogTitle id="alert-dialog-title"></DialogTitle>
	            <DialogContent>
									<TextField required
														 helperText={this.state.new_folder_error}
														 error={this.state.new_folder_error.length>0}
														 key='new_folder_text'
														 label="foldername"
														 inputProps={{ maxLength: 50 }}
														 fullWidth={true}
														 value={this.state.new_folder_text}
														 onChange={(e)=>this.checkDirectoryInput(e)} />

	            </DialogContent>
	            <DialogActions>
	              <Button size="small" onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
								<Button size="small" variant="contained" disabled={this.state.new_folder_text.length===0} onClick={()=>{this.sendAddDirectory()}} color="secondary">{ETVConstants.trans("save")}</Button>
	            </DialogActions>
	          </Dialog>
        </Box>
			)
		}
}

export default ContentLibrary
