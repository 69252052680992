import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage} from 'react-intl';
// front end version

export const VERSION = "2.8.8";

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const SERVER_ADDRESS = "elevatortvbackend.appspot.com";
//export const SERVER_ADDRESS = "/backend3-1";
//export const SERVER_ADDRESS = "http://192.168.1.11/backend3-1";
export const ON_PREMISE_VERSION = false;

export const WHATSAPP_BIZ_NR = "6598307109";


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function trans(msgID) {
  return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
}

export function getServerBase() {
    if(ON_PREMISE_VERSION) {
      //return "http://"+SERVER_ADDRESS;
      return SERVER_ADDRESS; //"/backend3-1"; // this will be like /te
    }else{
      return "https://"+SERVER_ADDRESS;
    }
}

export const EMAIL_REGEX =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
export function isEmail( emailAddr) {
  return EMAIL_REGEX.test(emailAddr);
}

export const PHONE_REGEX =  /^\+\s*[0-9\s*]{7,}$/;
export function isPhone( phone) {
  // remarks: ^abc$ --> means exact match
  // "/" start and "/" end tags
  // \s* as many whitespace as wishes
  // {7,} at least 7 numbers
  // \+ must start with +
  return PHONE_REGEX.test(phone);
}

export function compareDevice( a, b) {
  //console.log("localcompare "+a.device_name);
  //console.log("localcompare "+b.device_name);
  return a.device_name.localeCompare(b.device_name);
}

export function compareGroup( a, b) {
  return a.group_name.localeCompare(b.group_name);
}

export function filterOutEmptyGroups( groups) {
  var res = [];
  for(var i=0; i<groups.length; i++) {
      if(groups[i].device_ids.length>0) res.push(groups[i]);
  }
  return res;
}

export function sendCMDToServer(url) {
  var accessToken = localStorage.getItem("etv.access_token");
  var reqUserID = localStorage.getItem("etv.user_id");
  fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
    .then(res => res.json())
    .then(
      (result) => {
        if(result["result"]) {
          //console.log("ok");
        }else{
          console.log("result not ok="+JSON.stringify(result));
        }
      },
      (error) => {
        console.log("error="+error);
      }
    )
}

export function sendCMDToServerListener(url, resultListener) {
  var accessToken = localStorage.getItem("etv.access_token");
  var reqUserID = localStorage.getItem("etv.user_id");
  fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
    .then(res => res.json())
    .then(
      (result) => {
        resultListener(result);
      },
      (error) => {
        console.log("error="+error);
      }
    )
}


function getTimeAgoVal( mins) {
  var val = mins;
  if(val<60) return Math.round(val)+" minutes ago";
  val = val/60;
  if(val<24) return Math.round(val)+" hours ago";
  val = val/24;
  if(val<31) return Math.round(val)+" days ago";
  val = val/31;
  if(val<12) return Math.round(val)+" months ago";
  val = Math.round(val/12);
  if(val===1) {
    return Math.round(val)+" year ago";
  }else{
    return Math.round(val)+" years ago";
  }
}


export function getLastConnectInfo(time) {

  var d = new Date(time.replace(/-/g, "/"));
  var diffMins = getLastConnectMins(time);
  var msg = d.toLocaleString();
  if(diffMins<10) {
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#67f00c' }}  /></Tooltip>

  }else if(diffMins<60) {
      //less than 1 hour
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f5d247' }}  /></Tooltip>

  }else if(diffMins<1440) {
      //less than 1 day
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f59e07' }}  /></Tooltip>

  }else if(diffMins<10080) {
        //less than 1 week
        return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f56c11' }}  /></Tooltip>

  }else{
     //less than 1 week
     return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#96080c' }}  /></Tooltip>
  }
}

export function getLastConnectSymbol(time, startup, status) {

  var d = new Date(time.replace(/-/g, "/"));
  var diffMins = getLastConnectMins(time);
  var msg = d.toLocaleString();
  if(startup && startup.trim().length>0) {
    var startupDt = new Date(startup);
    msg = getTimeAgoVal(diffMins)+" (login: "+startupDt.toLocaleString()+")";
  }
  if(status==="offline") {
    return <Tooltip title={"(offline) "+msg} ><FiberManualRecordIcon size='large' style={{ color: '#fa2d33' }}  /></Tooltip>
  }
  //console.log("diffMins="+diffMins);
  if(diffMins<10) {
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#67f00c' }}  /></Tooltip>

  }else if(diffMins<60) {
      //less than 1 hour
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f5d247' }}  /></Tooltip>

  }else if(diffMins<1440) {
      //less than 1 day
      return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f59e07' }}  /></Tooltip>

  }else if(diffMins<10080) {
        //less than 1 week
        return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#f56c11' }}  /></Tooltip>

  }else{
     //less than 1 week
     return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: '#96080c' }}  /></Tooltip>
  }
}

export function getLastConnectMins( time) {
  // careful on IOS! --> use ".replace(..)"
  var d = new Date(time.replace(/-/g, "/"));
  var dNow = Date.now();
  var diffMins = (dNow - d)/60000;
  return diffMins;
}

////////////////////////////////////////////////////////////////////////////////

export function makeLayoutURLRelative(url) {
  if(url.indexOf("/contents")>0) {
    // it is a custom layout (stored in the individual's user directory)
    return makeContentURLRelative(url);
  }
  return url.substring(url.indexOf("/layouts"))
}

export function makeContentURLRelative(url) {
  return url.substring(url.indexOf("/contents"))
}

export function isAlphaNumeric( val) {
  return val.match(/^\w+$/) || val.length===0
}
